import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouteComponentProps } from "react-router";
import {   RangeKeyDict } from 'react-date-range';
import debounce from 'lodash/debounce';
import moment from "moment";
import _ from 'lodash';


// Customizable Area Start
import {
  ClassNameMap,
  ClassKeyOfStyles,
} from '@material-ui/styles';
import React ,{createRef}from "react";
import { logoutUser, setStorageData } from "../../../framework/src/Utilities";



interface EditBody {
  [key: string]: string | number[] | number
}

interface Body {
  data: EditBody
}
interface ApiData {
  contentType: string;
  method: string;
  endPoint: string;
  body?: Body | EditBody;
  type?: string;
}


export interface IRolesAndPermissionOptions {
  edit:  string;
  assign: string;
  view:string;  
  duplicate: string;
  idle?: string;
}

type CampiagnUser = {
  id: number;
  first_name: string;
  last_name: string | null;
  full_phone_number: string;
  country_code: string | null;
  phone_number: string | null;
  email: string;
  activated: boolean;
  device_id: string | null;
  unique_auth_id: string;
  password_digest: string | null;
  created_at: Date | null; 
  updated_at: Date| null; 
  user_name: string | null;
  platform: string | null;
  user_type: string | null;
  app_language_id: number | null;
  last_visit_at: Date | null; 
  is_blacklisted: boolean;
  suspend_until: Date | null; 
  status: string;
  role_id: number;
  full_name: string | null;
  gender: string | null;
  date_of_birth: Date | null; 
  age: number | null;
};

export type CampaignList = {
  data: any;  
  id:string,
  type: string,
  attributes: {
      company_name: string,
      industry: string,
      location: null | string,
      objective_id: number,
      budget: number | null,
      radius_id: number,
      duration: null,
      unique_campaign_id: string,
      created_at: Date,
      updated_at: Date,
      status: string,
      user_photo_link: string |  null,
      user: CampiagnUser
  }
}
export enum CampiagnAction {
  EDIT = "edit",
  DUPLICATE = "duplicate",
  VIEW = "view",
  ASSIGN = "assign"
}

export enum USER_ROLES {
  USER = 1,
  ADMIN_AND_EDITOR = 2
}

export enum CAMPAIGN_STATUS {
  NULL = "null",
  COMPLETED = "completed",
  INCOMPLETE = "incomplete"
}

interface Role {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  role_id: number;
}

interface AccountAttributes {
  email: string;
  first_name: string;
  full_phone_number: string;
  last_name: string;
  created_at: string;
  updated_at: string;
  device_id: string | null;
  unique_auth_id: string;
  area: string | null;
  role: Role;
}

export interface AccountData {
  id: string;
  type: string;
  attributes: AccountAttributes;
}

export interface UserRolesList {
  id: string;
  type: string;
  attributes: {
      email: string;
      first_name: string;
      full_phone_number: string;
      last_name: string;
      created_at: string;
      updated_at: string;
      device_id: null | string;
      unique_auth_id: string;
      role_id: number;
      area: string;
      role: {
          id: number;
          name: string;
          created_at: string;
          updated_at: string;
          role_id: number;
      }
  }
}

interface IRadius {
  id: number;
  name: string;
}

interface ICampaign {
  sales_rep_id: number;
  id: number;
  objective_id: number;
  radius_id: number;
  company_name: string;
  industry: string;
  location: string;
  created_at: string;
  updated_at: string;
  budget: string;
  radius: IRadius;
  duration: string;
  status: string;
  dmc: null | string
}

export enum ROLES_PERMISSION_OPTIONS {
    EDIT="edit",
    ASSIGN="assign",
    VIEW="view",
    DUPLICATE="duplicate",
    IDLE="idle"
}

type IErrorTokenUnauthorized = {
  message: string
}

type IErrorTokenInvalidToken = {
  token: string
}

interface IAPIResponseCampaignList {
  data?: CampaignList[]
  errors?: [IErrorTokenInvalidToken]
}

interface IAPIResponseUserProfileDetails {
  data: AccountData;
  errors?: [IErrorTokenInvalidToken]
}

interface IAPIResponseUserList {
  data: UserRolesList[];
  errors?: [IErrorTokenInvalidToken]
}

interface IAPIResponseAssignCampaign {
  data:ICampaign;
  errors?:[IErrorTokenInvalidToken]
}

interface RadiusDataItem {
  name: string;
  id: string;
  attributes: {
    id: number;
    name: string;
  };
}

// Customizable Area End

export const configJSON = require("./config.js");

export interface Props extends RouteComponentProps {
  navigation: any;
  id: string;
  classes: ClassNameMap<ClassKeyOfStyles<string>>;

  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  isAuthenticated: boolean
  loading: boolean;
  selectedCampaign: string | null;
  isTableRowHover: string | null;
  campaignList: CampaignList[] | null | undefined | any;
  campaignStatus: boolean;
  anchorEl: HTMLButtonElement | null;
  campainActionHover: ROLES_PERMISSION_OPTIONS;
  fiterItemsAnchorEl: HTMLButtonElement | null;
  isFilterByDate: boolean;
  isFilterByDistrict: boolean;
  filterByDateAnchorEl: HTMLLIElement | null;
  filterCampaignFromDate: Date | undefined;
  filterCampaignToDate: Date | undefined;
  selectedFromDate:string | null;
  selectedToDate:string | null;
  isShowSelectedDateRange: boolean;
  userAssignListSearchQuery: string;
  userAssignList: UserRolesList[] | null;
  userProfileDetails: AccountData | null;
  campaignListSearchQuery:string;
  isFilterActive:boolean,
  listTop:boolean
  searchPerformed: boolean,
  radiusData: RadiusDataItem[],
  openLocaton : null | HTMLElement,
  showFullText:boolean,
  expandedCompany:string,
  filteredCampaignListByDate:boolean,
  currentPage:number,
  hasMore:boolean,
  campaignListLocationId : any,
  openModel:boolean,
  mountCount: number,
  visitedDashboard:boolean
  RediusItems : string;
  select_lang: any;
  del:any;
  sortedCampaignList: any[];
  shouldRenderEdit: boolean;
  results: any[],
  objectiveCamID:any,
  flagSelectedCountry:string,
  flagSelectedItalian:string,
  showLoader:boolean,
  Norecord:any,
  showObjectiveArray:any
  
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getAllCampaignListApiCallId: string = '';
  getUserProfileDetailsApiId: string = '';
  getAllUserListToAssignCampaignsApiId: string = '';
  assignCampaignToAnotherUserApiId: string = '';
  assests: React.RefObject<any>;
  duplicateCampaignCallId: string = '';
  getRadiusCallId:string="";
  campaignsDeleteCallID:string ="";
  getCampaignCallID:string="";
  getObjectiveDasCallId:string=""

  tableRef: React.RefObject<HTMLTableElement | null>;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
      this.tableRef = React.createRef();
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      isAuthenticated: false,
      loading: false,
      selectedCampaign:null,
      isTableRowHover:null,
      campaignList:null || [],
      campaignStatus:false,
      anchorEl:null,
      campainActionHover:ROLES_PERMISSION_OPTIONS.IDLE,
      fiterItemsAnchorEl:null,
      isFilterByDate:false,
      isFilterByDistrict:false,
      filterByDateAnchorEl:null,
      filterCampaignFromDate:new Date(),
      filterCampaignToDate:new Date(),
      selectedFromDate:null,
      selectedToDate:null,
      isShowSelectedDateRange:false,
      userAssignListSearchQuery:'',
      userAssignList:null,
      userProfileDetails: null,
      campaignListSearchQuery:'',
      isFilterActive:false,
      listTop:false,
      searchPerformed: false,
      radiusData: [] ,
      openLocaton : null,
      showFullText:false,
      filteredCampaignListByDate:false,
      expandedCompany:'',
      currentPage: 1,
      hasMore:false,
      campaignListLocationId : '',
      RediusItems : '',
      openModel:false,
      mountCount: 0,
      visitedDashboard: false,
      select_lang: {name: []},
      del:"",
      sortedCampaignList:[],
      shouldRenderEdit: false,
      results: [],
      objectiveCamID:false,
      flagSelectedCountry:'german',
      flagSelectedItalian:'italian',
      showLoader:false,
      Norecord:[],
      showObjectiveArray:[]
    };
    this.assests = createRef()
 this.tableRef = React.createRef();
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.getObjectiveDetails()
   const visited = localStorage.getItem("visitedDasboard")  
   localStorage.removeItem('mycurrentPage') 
   localStorage.removeItem("navigate")
   if(visited){
    this.setState({openModel:true})
   }
   let keysToRemove = [ "rolesandpermssion","allCampaigndata",'visitedSetup', 'editDashboard','navigate','campaignID','visitedrecomm','visitedBudget', "persistId",'visitedObjective',"visitedLocation","visitedChannel","visitedAddon","visitedConfigure","duraa","showData","visitedView","setUpCampaignID"];
   keysToRemove.forEach(function(key) {
 localStorage.removeItem(key);
});
    this.getAccessTokenQueryParams()
    await this.getAllCampaignList()
    await this.getUserProfileDetails()
    await this.getRadiusDetails()
    localStorage.setItem("visitedDasboard","visited")

    const selectedCountry = localStorage.getItem("selectedCountry");
    const lang : any = {
      german: {
               name:["DMC","Firmenname","Kampagnen-ID", "Status", "Datum (Erstellung)","Aktionen","Bearbeiten", "Duplizieren", "Anzeigen", "Zuweisen", "Hier tippen...","Verwalten Sie Ihre Kundenkampagnen", "Abbrechen", "Einstellen", "Nach Datum", "Nach Ort","Suchen Sie nach Kampagnen-ID, DMC und Firmennamen", "Nur abgeschlossene anzeigen", "Filter", "Kampagnenliste","Möchten Sie die Kampagne vor dem Auszug speichern ?", "NEIN","Ja, Speichern"],
              },
      french: {
               name:["DMC","Nom de la société", "ID de la campagne","Statut","Date (création)", "Actions","Modifier", "Dupliquer","Visualiser", "Attribuer", "Tapez ici...", "Gérez les campagnes de vos clients", "Annuler", "Définir", "Par date", "Par lieu","Rechercher l'ID de campagne, le DMC, le nom de l'entreprise", "Afficher seulement terminé", "Filtres", "Liste des campagnes","Avant de déménager, souhaitez-vous sauvegarder la campagne ?","Non","Oui, enregistrer"],
              },
      italian: {
               name:["DMC","Nome dell'azienda","ID campagna", "Stato della campagna", "Data (creazione)", "Azioni","Modifica", "Duplica", "Visualizza", "Assegna", "Digita qui...", "Gestisci le campagne dei tuoi clienti", "Annulla", "Imposta", "Per data", "Per località", "Cerca ID campagna, DMC, nome dell'azienda", "Mostra solo completato","Filtri", "Campaign List","Prima di uscire vuoi salvare la campagna ?", "No", "Sì, salva"],
              },
    };

    const selected_Language = lang[selectedCountry as any] || { name: []};
    this.setState({select_lang:selected_Language})
    
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.handleApiResponses(message);
    }
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const apiRequestID = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    if (apiRequestID === this.getCampaignCallID){
      this.getCampaignReceiver(responseJson);
    }
    if(apiRequestID ==this.getRadiusCallId){
      this.getRadiusReceiver(responseJson);
    }

    if(this.campaignsDeleteCallID != null &&
      this.campaignsDeleteCallID ===
      apiRequestID ){
        if(responseJson.message){
          this.getAllCampaignList();          
            this.setState({
    openModel:false
    })   
        }   
      }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidUpdate(
    prevProps: Readonly<Props>,
  prevState: Readonly<S>,
    snapshot?: SS | undefined
  ) {
    if (
      prevState.filteredCampaignListByDate !== this.state.filteredCampaignListByDate && 
      (this.state.filterCampaignFromDate !== undefined && this.state.filterCampaignToDate !== undefined)
      ) {
     await this.getAllCampaignList()
    }
    if (prevState.campaignStatus !== this.state.campaignStatus || prevState?.campaignListSearchQuery !== this.state.campaignListSearchQuery) {

      await this.getAllCampaignList()
     
    }
    if (prevState.filterCampaignFromDate !== this.state.filterCampaignFromDate && prevState.filterCampaignToDate !== this.state.filterCampaignToDate) {
      await this.getAllCampaignList()
    }
    if (prevState.campaignListLocationId !== this.state.campaignListLocationId) {
      await this.getAllCampaignList()
    }
  }

  handleApiResponses = async (message: Message) => {
    const apiRequestCallIds = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJsons = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if( responseJsons &&responseJsons.statusCode === 401){
        logoutUser();          
        this.props.history.push("/EmailAccountLoginBlock");
      }
     
    if (apiRequestCallIds && responseJsons) {
      if (apiRequestCallIds === this.getAllCampaignListApiCallId) {
        this.campaignListApiResponse(responseJsons)
      }else if(apiRequestCallIds===this.getObjectiveDasCallId){
        this.objectiveName(responseJsons)
      }
      else if (apiRequestCallIds === this.getUserProfileDetailsApiId) {
        this.profileDetailsApiResponse(responseJsons)
      }
      else if(apiRequestCallIds === this.getAllUserListToAssignCampaignsApiId){
        this.userAssignListAPiResponse(responseJsons)
      }
      else if (apiRequestCallIds === this.assignCampaignToAnotherUserApiId) {
        this.assignCampaignToAnotherUserApiRespose(responseJsons)
      }
      else if (apiRequestCallIds === this.duplicateCampaignCallId) {
        this.duplicateCampaignApiResponse(responseJsons)
      }
    }
  }

  duplicateCampaignApiResponse = async (responseJson: any) => {
    if (!responseJson.errors) {
      this.setState({ campaignList: [] ,currentPage:1},()=>{
        this.getAllCampaignList()
        this.setState({ campainActionHover: ROLES_PERMISSION_OPTIONS.IDLE })
      })
     
    }
    else if (responseJson.errors[0].token === "Invalid token") {
      this.props.history.push("/EmailAccountLoginBlock")
    }
  }
  getCampaignRecommendationData = async (): Promise<void> =>{
  
    const  persistId= localStorage.getItem("persistId")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("accessToken")
     };
  
     const requestMessageList = new Message(
         getName(MessageEnum.RestAPIRequestMessage)
     );
  
     this.getCampaignCallID = requestMessageList.messageId;
  
     requestMessageList.addData(
       getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
     );
     requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
  );
     requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${"bx_block_custom_form/get_campaign?id="}${persistId}`
     );
     runEngine.sendMessage(requestMessageList.id, requestMessageList);
  
   }
   getObjectiveDetails = async (): Promise<void> => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("accessToken")
     };
     const language = localStorage.getItem('selectedCountry');
     const requestMessageList = new Message(
         getName(MessageEnum.RestAPIRequestMessage)
     );
 
     this.getObjectiveDasCallId = requestMessageList.messageId;
 
     requestMessageList.addData(
       getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
     );

     requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      // "account_block/profile?token=" + token 
      `${"bx_block_custom_form/objectives"}?language=${language}`
     );
 
     requestMessageList.addData(
         getName(MessageEnum.RestAPIRequestHeaderMessage),
         JSON.stringify(header)
     );
 
     runEngine.sendMessage(requestMessageList.id, requestMessageList);
 }
 objectiveName=(responseJson:any)=>{
  this.setState({showObjectiveArray:responseJson.data})
 }
   getCampaignReceiver=(responseJson:any)=>{
    this.getObjectiveName(responseJson.campaign.attributes.objective_id)
    setStorageData("campingIndustry",responseJson.campaign.attributes.industry)
    const row_Id:any = localStorage.getItem("rowId");
    const campaign_Status = localStorage.getItem("status");
    const current_page = localStorage.getItem("current_page");
    const language=responseJson.language && responseJson.language.charAt(0).toLowerCase() + responseJson.language.slice(1)
    localStorage.setItem('selectBenchmarklanguage',language);
    localStorage.setItem("objective_id",responseJson.campaign.attributes.objective_id)
 if (campaign_Status && !["Completed", "Completato", "Abgeschlossen", "Terminé"].includes(campaign_Status)) {
  this.redirectBasedOnPage(current_page);     
} else {
  localStorage.setItem("sideBarOpen","true");   
  
  const confi = {activeKey:current_page,visitedKey:['setup', 'objective', 'channels', 'location', "budget", "addons"]}
  localStorage.setItem("sidebarData",JSON.stringify(confi))
  

  this.props.history.push(`/Configuration/${row_Id}`);  
 
}   
 this.setState({objectiveCamID:true})
   }
   NorecordFunction=(responseJson:any)=>{
    if(this.state.currentPage === 1){
      this.setState({Norecord:responseJson.data})
    }
   }
  campaignListApiResponse = async (responseJson: any) => {
    this.NorecordFunction(responseJson);
    this.setState({showLoader:false})
        try {
      if (!responseJson.errors) {
        if((this.state.selectedFromDate && this.state.selectedToDate) || this.state.campaignListLocationId){         
          
          this.setState({ campaignList: Array.isArray(responseJson) ? [ ...this.state.campaignList,...responseJson ] : [...responseJson] , currentPage: this.state.currentPage + 1 });
        }else{         
          
          this.setState((prevState) => ({            
            campaignList: Array.isArray(responseJson) ? [ ...this.state.campaignList,...responseJson ] : [...this.state.campaignList],
            currentPage: prevState.currentPage + 1
          }));         
         }
      } else if (responseJson.errors[0].token === "Invalid token") {
        this.props.history.push("/EmailAccountLoginBlock");
      }
    } catch (error) {
      this.setState({ loading: false ,hasMore: false });
    }
  }

  //istanbul ignore next
  profileDetailsApiResponse = async (responseJson: IAPIResponseUserProfileDetails) => {
    if (!responseJson.errors) {
      this.setState({ userProfileDetails: responseJson.data })
      localStorage.setItem("userDetails", JSON.stringify(responseJson.data))
    }
    else if (responseJson.errors[0].token === "Invalid token") {
      this.props.history.push("/EmailAccountLoginBlock")
    }
  }


  userAssignListAPiResponse = (responseJson:IAPIResponseUserList)=>{
    if (!responseJson.errors) {
       this.setState({userAssignList: responseJson.data})
    }
    else if (responseJson.errors[0].token === "Invalid token") {
      this.props.history.push("/EmailAccountLoginBlock")
    }
  }

  assignCampaignToAnotherUserApiRespose = (responseJson:IAPIResponseAssignCampaign)=>{
    if (!responseJson.errors) {
      this.setState({ userAssignListSearchQuery:"",userAssignList:[],currentPage:1,campaignList:[]},()=>{
         this.getAllCampaignList()
       this.setState({ campainActionHover: ROLES_PERMISSION_OPTIONS.IDLE })
         
      })
    }
   else if (responseJson.errors[0].token === "Invalid token") {
     this.props.history.push("/EmailAccountLoginBlock")
    }
  }

  getAccessTokenQueryParams = () => {
    const params = new URLSearchParams(window.location.search)
    const token = params.get('access_token')

    if (token) {
      localStorage.setItem("accessToken", token)
      this.setState({ isAuthenticated: true })
      params.delete('access_token');
      const newUrl = `${window.location.pathname}`;
      window.history.replaceState({}, document.title, newUrl);
    }
    else {
      const isAuthenticated = localStorage.getItem('accessToken') ? true : false;
      if (!isAuthenticated) {
        this.props.history.push("/EmailAccountLoginBlock")
      }
    }

  }

  filterCampaignCompleteAndIncomplete = ()=>{
   this.setState({campaignStatus:!this.state.campaignStatus, currentPage: 1, campaignList:[]})
  //  this.setState({showLoader:false})
  }
  
  selectTableRowHoverId = (rowId:string)=>{
    this.setState({isTableRowHover:rowId})  

  }

  removeTableRowHoverId = () => {
    this.setState({ isTableRowHover: null })
  }

   //istanbul ignore next
  handleSeletedRowAction = (event:HTMLButtonElement, rowId: string | any,campaign:any) => {
    if(campaign.attributes.user.role_id === this.state.userProfileDetails?.attributes?.role.id){
      this.setState({shouldRenderEdit:true})
    }else{
      this.setState({shouldRenderEdit:false})
    }
    let status = campaign?.attributes?.status;
    let current_page = campaign?.attributes?.campaign_progress?.current_page
    this.setState({ anchorEl: event,selectedCampaign: rowId })
    localStorage.setItem("current_page",current_page)
    localStorage.setItem("status",status)
    localStorage.setItem("rowId",rowId)    
  }

  handelClosePopover = () => {
    this.setState({
      anchorEl: null,
      selectedCampaign: null,
      userAssignList: [],
      userAssignListSearchQuery: ""
    })
  }
  handleCompanyNameClick = (companyName: any) => {
    this.setState((prevState) => ({
      expandedCompany: prevState.expandedCompany === companyName ? '' : companyName,
    }));
  }
  handelPopoverMouseHover = (action: ROLES_PERMISSION_OPTIONS) => {
    this.setState({ campainActionHover: action })
  }

  handelPopoverMouseLeave = () => {
    this.setState({ campainActionHover: ROLES_PERMISSION_OPTIONS.IDLE })
  }

  handelClickFilterCampaigns = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ fiterItemsAnchorEl: event.currentTarget,isFilterActive:true })
  }

  closeFilterCampaignsMenu = () => {
    this.setState({ fiterItemsAnchorEl: null,isFilterActive:false })
  }

  handelDateFilterHover = () => {
    this.setState({ isFilterByDate: true })
  }

  handelDateFilterOnMouseLeave = () => {
    this.setState({ isFilterByDate: false })
  }

  handelDistrictFilterHover = () => {
    this.setState({ isFilterByDistrict: true })
  }

  handelDistrictFilterOnMouseLeave = () => {
      this.setState({ isFilterByDistrict: false })

  }

  handelDatePickePopover = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    this.setState({
      isFilterByDate: true,
      filterByDateAnchorEl: event.currentTarget,
      isFilterByDistrict:false
    })
  }

  handelFilterCampaignDate = (selectedRanges: RangeKeyDict) => {
    const fromDate = selectedRanges.selection.startDate;
    const toDate = selectedRanges.selection.endDate;
    this.setState({ filterCampaignFromDate:fromDate , filterCampaignToDate:toDate ,searchPerformed: true,isFilterByDistrict: false})
  }

  filterBtnActiveOnMouseEnter = ()=>{
    this.setState({isFilterActive:true})
  }

  filterBtnActiveOnMouseLeave = ()=>{
    this.setState({isFilterActive:false})
  }

  closeDatePickerPopover = () => {
    this.setState({
      filterByDateAnchorEl: null,
      fiterItemsAnchorEl: null,
      filterCampaignFromDate: new Date(),
      filterCampaignToDate: new Date(),
      isFilterByDate: false
    })
  }

  closeByDistrictPopover = () => {
    this.setState({
      openLocaton: null,
      isFilterByDistrict: false,
      fiterItemsAnchorEl: null,
    })

  }

  handelSetDateRange = () => {
    this.setState({showLoader:true})
    if (this.state.filterCampaignFromDate && this.state.filterCampaignToDate) {
      this.setState({
        selectedFromDate:moment(this.state.filterCampaignFromDate).format("DD/MM/YYYY"),
        selectedToDate:moment(this.state.filterCampaignToDate).format("DD/MM/YYYY"),
        filterByDateAnchorEl: null,
        fiterItemsAnchorEl: null,
        isShowSelectedDateRange: true,
        filteredCampaignListByDate:!this.state.filteredCampaignListByDate,
        isFilterByDate: false,
        currentPage:1,
        campaignList:[]
      })
    }
  }

  handelRemoveDateRange  = ()=>{
      this.setState({
        isShowSelectedDateRange:false,
        filterCampaignFromDate:new Date(),
        filterCampaignToDate:new Date(),
        selectedFromDate:null,
        selectedToDate:null,
        campaignList:[],
        currentPage: 1
      })
    }

  
  debounceSearchQuery = debounce(()=>{
    this.getAllCampaignList()
  },1500)


  handelCampaignListSearchQuery = ((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {      
    this.setState({showLoader:true})  
    const searchQuery = event?.target.value;
      this.setState({ campaignListSearchQuery: searchQuery, searchPerformed: true, currentPage: 1, campaignList:[]});
  }); 

  debounceUserAssignListQuery = debounce(()=>{
    this.getAllUserListToAssignCampaigns()
  },1500)

  handelSearchUserQuery = (event:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)=>{
    const searchQuery = event?.target.value;
    this.setState({userAssignListSearchQuery:searchQuery})
    if(searchQuery !== ""){
      this.debounceUserAssignListQuery()
    }
    else{
      this.setState({userAssignList:null})
    }
    const rect = this.assests.current.getBoundingClientRect();
    if(rect.top>450){
      this.setState({listTop: true})
    }
    else if((rect.top>380 && innerWidth<1370)){
      this.setState({listTop: true})
    }
    else if((rect.top>200 && innerWidth<350)){
      this.setState({listTop: true})
    }
    else{
      this.setState({listTop: false})
    }

  }
  //istanbul ignore next
  getCampaignListEndPoint = ()=>{

    const { 
      selectedFromDate,
      selectedToDate,
      campaignListSearchQuery,
      campaignStatus,
      currentPage,
      campaignListLocationId,

    } = this.state

    let queryParameters = [];
    const selectedCountry = localStorage.getItem('selectedCountry');

    if (selectedCountry) {
      queryParameters.push(`language=${selectedCountry}`);
  }


    if (campaignListSearchQuery) {
        queryParameters.push(`query=${campaignListSearchQuery}`);
    }

    if (campaignStatus) {
        queryParameters.push(`status=${campaignStatus}`);
    }

    if (selectedFromDate && selectedToDate) {
        queryParameters.push(`start_date=${selectedFromDate}`);
        queryParameters.push(`end_date=${selectedToDate}`);
    }

    if (currentPage) {
        queryParameters.push(`page=${currentPage}`);
    }
    
    if (campaignListLocationId) {
      queryParameters.push(`radius_id=${campaignListLocationId}`);
  }


    const queryString = queryParameters.length > 0 ? '?' + queryParameters.join('&') : '';

    return `${configJSON.campaignListEndPoint}${queryString}`;
  }
  
   
  getAllCampaignList= async ()=>{
    this.setState({showLoader:true})
    const authToken =  localStorage.getItem('accessToken') || '';
    
    const header = {
      "Content-Type": "application/json",
      token: authToken,
    };

    const endPoint = this.getCampaignListEndPoint()

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllCampaignListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }   
  

  getUserProfileDetails = async () => {

    const authToken = localStorage.getItem('accessToken') || '';

    const header = {
      "Content-Type": "application/json",
      token: authToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUserProfileDetailsApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userProfileEndPoint,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  
  getAllUserListToAssignCampaigns = ()=>{
    const authToken =  localStorage.getItem('accessToken') || '';

    const header = {
      "Content-Type": "application/json",
      token: authToken,
    };

    const endpoint = `${configJSON.userListEndPoint}?query=${this.state.userAssignListSearchQuery}`

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllUserListToAssignCampaignsApiId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  
  assingCampaingToAnotherUser = (campaignId:string | null,userId:string)=>{
    const authToken =  localStorage.getItem('accessToken') || '';

    const header = {
      "Content-Type": "application/json",
      token: authToken,
    };
    
    const body = {
      data: {
        campaign_id: campaignId,
        user_id: userId
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.assignCampaignToAnotherUserApiId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.assignCampaingToUser,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    )
    
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  duplicateCampaign = (selectedCampaign:  string | null) => {
 
    const authToken = localStorage.getItem('accessToken') || '';
    const endPoint = `${configJSON.duplicateCampaign}/${selectedCampaign}/duplicate`

    const header = {
      "Content-Type": "application/json",
      token: authToken,
    };

    const body = {
      data: {
        campaign_id: selectedCampaign,
        
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.duplicateCampaignCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    )

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
// istanbul ignore next
  renderUniqueCampaignId = (campaign: { attributes: {
    [x: string]: any; unique_campaign_id: string | any[]; 
}; }) => {
    if (campaign.attributes.unique_campaign_id) {
      if (campaign.attributes.unique_campaign_id.length > 14 && !this.state.showFullText) {
        return `${campaign.attributes.unique_campaign_id.slice(0, 14)}....`;
      } else {
        return campaign.attributes.unique_campaign_id;
      }
    }
 
  };


  handelRemoveLocation  = ()=>{
    this.setState({
      isFilterByDistrict:false,
      openLocaton:null,
      RediusItems: '',
      campaignList:[],
      currentPage: 1,
      campaignListLocationId : ''
    })
  }

    handleClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({
      openLocaton : this.state.openLocaton ? null : event?.currentTarget,
      isFilterByDistrict : true,
    });
  };

  handleRadiusDataId = (item : any) => {
    
     this.setState({
      campaignListLocationId: item.id,
      RediusItems : item.name,
      isFilterByDistrict : false,
      fiterItemsAnchorEl: null,
      filteredCampaignListByDate:!this.state.filteredCampaignListByDate,
      currentPage : 1,
      campaignList:[]
  });

  }

  getRadiusDetails = async (): Promise<void> => {
    const selectedCountry = localStorage.getItem('selectedCountry');
    const header = {"Content-Type": configJSON.validationApiContentType, token: localStorage.getItem("accessToken")};
  
     const requestMessageList = new Message(
         getName(MessageEnum.RestAPIRequestMessage)
     );     
  
     this.getRadiusCallId = requestMessageList.messageId;
  
     requestMessageList.addData(
       getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
     ); 

     requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getRadiusEndPoint}?language=${selectedCountry}`
     );
  
     requestMessageList.addData(
         getName(MessageEnum.RestAPIRequestHeaderMessage),
         JSON.stringify(header)
     );
     runEngine.sendMessage(requestMessageList.id, requestMessageList);
  };
  
  getRadiusReceiver=(responseJson:any)=>{
    this.setState({radiusData: responseJson})
  };
 //istanbul ignore next
  handleEdit = async() => {
    
    localStorage.setItem("editDashboard", "true");
    const row_Id:any = localStorage.getItem("rowId");
   localStorage.setItem("persistId",row_Id)  
    localStorage.setItem("campaignID",row_Id)
    await this.getCampaignRecommendationData()
    this.setState({ campainActionHover: ROLES_PERMISSION_OPTIONS.IDLE }) 
  }
  handleViewClick=async()=>{
    localStorage.setItem("editDashboard", "true");
    localStorage.setItem("visitedView","visitedView")
    localStorage.setItem("showData","true")
    const row_Id:any = localStorage.getItem("rowId");
    localStorage.setItem("persistId",row_Id)
    localStorage.setItem("campaignID",row_Id)
     await this.getCampaignRecommendationData()
    this.setState({ campainActionHover: ROLES_PERMISSION_OPTIONS.IDLE })
  }
   //istanbul ignore next
  
  getObjectiveName=(ids:any)=>{
    const newarray=this.state.showObjectiveArray && this.state.showObjectiveArray.filter((i:any)=>i.id===ids)
    setStorageData("clickObjectiveName",newarray[0]?.name)
   }
  navigate = (campaignid: number, campaign: any) => { 
    localStorage.setItem("editDashboard", "true");
   if (this.state.userProfileDetails?.attributes?.role?.role_id === USER_ROLES.USER && (campaign.attributes.user.role_id !== this.state.userProfileDetails?.attributes?.role.id)){
    localStorage.setItem("visitedView","visitedView")
    localStorage.setItem("showData","true")
   }

   const objectiveid=campaign.attributes.objective_id
   this.getObjectiveName(objectiveid)
   setStorageData("campingIndustry",campaign.attributes.industry)
   localStorage.setItem("objective_id",objectiveid)
   const lang=campaign.attributes.language && campaign.attributes.language.charAt(0).toLowerCase() + campaign.attributes.language.slice(1)
   localStorage.setItem('selectBenchmarklanguage',lang);
    localStorage.setItem("rowId",campaignid.toString());
    localStorage.setItem("persistId",campaignid.toString())
    localStorage.setItem("campaignID",campaignid.toString())
    const status = campaign.attributes.campaign_progress.current_page;
    const current_page = localStorage.getItem("current_page");   
    const incompleteStatuses = ["Completed", "Completato", "Abgeschlossen", "Terminé"]
    

    if  ( incompleteStatuses.includes(campaign.attributes.status)) {
       setStorageData("campaignID",campaignid);
       localStorage.setItem("frompage","Dashboard")      
       this.props.navigation.navigate("Congratulations", { campaignid:campaignid  });
    } else {
      
   
      this.redirectBasedOnPage(status);
      
    }
  }
   //istanbul ignore next
  redirectBasedOnPage = (page: string | null) => {
    const pageMapping: { [key: string]: string } = {
      recommendation: "/Recommendation",
      setup: "/SetUp",
      location: "/Location",
      objective: "/Objective",
      addons: "/Addons",
      channels: "/Channels",
      quote: "/Quote",
      budget: "/Budget",
      configration: "/Configuration",
      campaign_details: "/Congratulations",
    };
  
    const path = pageMapping[page || ''];
    if (path) {
      this.props.history.push(path);
    }
  };
  
  openDialog = () => {
    this.setState({
       openModel: true 
    });
  };

  closeModal = () => {
    this.setState({ 
      openModel: false
    });
    localStorage.removeItem("visitedDasboard");
    localStorage.removeItem("closeModal");
    this.props.navigation.goBack();
  };
//istanbul ignore next
  campaignNo =() =>{
    this.deletecampaigns();   
    localStorage.removeItem("visitedDasboard")
    localStorage.removeItem("closeModal");
  }

  campaignSave =()=>{
    this.props.history.push("/Dashboard");
    localStorage.removeItem("closeModal");
    this.setState({
    openModel:false
    }) 
  }
//istanbul ignore next
  deletecampaigns = () => {    
    let Camping_Id = localStorage.getItem('campaignID')
    const header = {
        "Content-Type": configJSON.validationApiContentType,
         token: localStorage.getItem("accessToken")
    };

    const requestMessageList = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.campaignsDeleteCallID = requestMessageList.messageId;

    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.campaignsAPIMethod
    );

    requestMessageList.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),`${configJSON.deletecampaignsEndPoint}?id=${Camping_Id}`
    );

    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  };
  // Customizable Area End

}