import React from "react";

import {
  Box,
  Button,
  // Customizable Area Start
  Zoom,
  Tooltip,
  Grid,
  Typography,
  AccordionSummary,
  Accordion,
  FormControlLabel,
  Checkbox,
  AccordionDetails,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Switch,
  SwitchClassKey,
  SwitchProps,
  MenuItem,
  DividerProps,
  styled,
  Modal,
  Theme,
  Dialog,
  DialogContent
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import {
  createTheme,
  ThemeProvider,
  withStyles,
  createStyles
} from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
// import { Divider } from "react-native-elements";
import Sidebar from "../../../components/src/SideBar.web";
import { withRouter } from "react-router-dom";
import { Link } from 'react-router-dom';
import {
  info,
  expandIcon,
  checked,
  unchecked,
  google,
  bannerad,
  printads,
  cancelImage
} from "./assets";

const ParentAccordian = styled('div')({
  // marginLeft:"-12px",
  marginTop:'-34px',
  
  '& .MuiAccordion-root:before':{
    backgroundColor: '#b4b4b4'
  },
  '& .MuiAccordion-root:first-child':{
    borderBottom: '1px solid #b4b4b4'
  },
    '& .MuiAccordion-root:last-child':{
    borderRadius:'0px'
  },
  
  "& .MuiAccordion-root.expanded + .MuiAccordion-root": {
    borderTop: "none"
  },


  '@media (max-width: 500px)': {
    marginLeft:"12px",
    marginTop:'-20px',
  }
})

const CustomAccordion = styled(Accordion)({
  borderBottom: '1px solid #b4b4b4',
  '& .MuiCollapse-entered': {
    marginBottom: 10
  },
  '& .MuiPaper-root':{
    color:'#b4b4b4'
  },
})


const Custom4BoxView = styled(Box)({
  display:'flex',
  // width:'100%',
  padding: '40px 16.5px 0px 16px',
  '& .MuiAccordionSummary-root':{
    padding:'0px 7px',
   },
   '& .MuiAccordionSummary-content':{
    padding:'8px 0px',
   },
  '@media (max-width: 961px)': {
    display:'flex',
    // background: 'red',
     flexDirection:"column" ,
     padding: '78px 18px 0px 21px',
  },
  '@media (max-width: 500px)': {
    padding: '66px 28px 0px 10px', 
  },
  '@media (max-width: 380px)': {
    padding: '66px 14px 0px 12px', 
  },
  "& .Cursor":{
    "& .MuiSwitch-input":{
      cursor:"default !important"
    },
    
  }
})
const CustomBoxViewSubBox=styled(Box)({
  
  backgroundColor: "#FFFFFF",
  height: "auto",
  boxShadow: "0px 4px 16px 0px #00000040",
  padding: "35px 29.4px 35px 24px",
  // padding: "20px 20px",
  borderRadius: "24px",
  marginTop:"25%",
  '@media (min-width: 500px)': {
    marginLeft:'1px !important'
  },
  '@media (max-width: 1050px)': {
    marginTop:"20px",
  },
  
  '@media (max-width: 1370px)': {
    marginTop:"17%",
  },
  '@media (min-width: 1900px)': {
    marginTop:"20%",
  },
})

const CustomBoxViewRight = styled(Box)({
 
  width:'32.2%',
  marginLeft:'16px',
  minHeight:'910px',
  '@media (max-width: 961px)': {
    width:'98%',
    marginLeft:'unset',
    marginTop:'26px',
  },
  '@media (max-width: 500px)': {
    marginLeft:'12px',
   
  },
  '@media (max-width: 395px)': {
    marginLeft:'10px',
   
  },
  '@media (max-width: 375px)': {
    marginLeft:'unset',
    width:'100%',
  },
})
const CustomBoxViewleftBox= styled(Box)({
  width:'70%',
  '@media (max-width: 961px)': {
    width:'100%',
    
  },
  '& .MuiMenuItem-root:hover':{
    color:'black'
  },
  '& .MuiMenuItem-root.Mui-selected:hover':{
    color:'black'
  },
  
})
const CustomBoxView = styled(Box)({
  backgroundColor: "#FFFFFF",
    height: "calc(100vh - 136px)",
    marginBottom:'40px',
    boxShadow: "0px 4px 16px 0px #00000040",
    padding: "28px 42px",
    borderRadius: "38px",
    minHeight:'850px',
    // height: "970px",
    overflowY: "auto" as const,
    "@media (max-width: 960px)": {
    //  marginTop:"50px",
     marginRight:'10px'
    },
    "@media (min-height: 1024px)": {
      // minHeight:'896px',
     },
     "@media (max-width: 500px)": {
      boxShadow: "none",
      borderRadius: "unset",
      padding: "unset",
      marginTop:'20px',
      marginRight:'unset'
     }
})
const CustomBoxViewButton : any=styled(Box)({
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
  "@media (max-width: 1440px)": {
    gap:"10px",
   },
  "@media (max-width: 960px)": {
   marginTop: "40px",
  },
  "@media (max-width: 500px)": {
    marginTop: "45px",
    marginBottom:'38px',
  },
})
const theme = createTheme({
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  overrides: {
    MuiGrid: {
      root: {
        "&.MuiGrid-spacing-xs-2": {
          "@media (max-width: 960px)": {
            marginTop:"65px",
          },
        },
      },
    },
    MuiMenuItem: {
      root: {
        "&:hover, &.Mui-selected  &.Mui-selected:hover": {
          
          backgroundColor: "transparent !important",
         
        },
      },
    },
    MuiMenu: {
      list: {
        padding: "24px",
      },
      paper: {
        borderRadius: "12px",
      },
    },
    MuiListItem: {
      root: {
        "&:hover, &.Mui-selected": {
          backgroundColor: "transparent !important",
        },
      },
    },
    MuiDivider: {
      root: {
        borderColor: '#F8FAFC',
        borderWidth: '1px',
        borderStyle: 'solid',
      },
    },
    MuiAccordionSummary: {
      content: {
        margin:"7px 0px",
        "&.Mui-expanded": {
          padding: "0px",
          margin: "0px",
        },
      },
      root: {
        '&[class*="Mui-expanded"]': {
          margin: '0px !important',
          minHeight :"48px"
        },
      },
    },
    MuiAccordion: {
      root: {
        '&[class*="Mui-expanded"]': {
          margin: '0px !important',
        },
      },
    },
  },
});
const WhiteOnBlackTooltip = withStyles((theme) => ({
  tooltip: {
    color: "black",
    // left: "40px",
    height: "auto",
    padding: "15px 20px 15px 20px",
    marginTop: "20px",
    backgroundColor: "#E9E9E9",
    fontSize: "16px",
    fontFamily: "Panton Narrow Regular",
    fontWeight: 400,
    maxWidth: "300px",
    borderRadius: "10px",
    borderTopRightRadius: "10px",
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
    position: "relative",
  },
  arrow: {
    content: '""',
    color:"#E9E9E9"
  },
}))(Tooltip);

const WhiteOnBlackTooltipRec : any = withStyles((theme) => ({
  tooltip: {
    color: "black",
    height: "auto",
    padding: "12px 20px 12px 20px",
    marginTop: "20px",
    backgroundColor: "#E9E9E9",
    fontSize: "16px",
    fontFamily: "Panton Narrow Regular",
    fontWeight: 400,
    maxWidth: "205px",
    borderRadius: "10px",
    borderTopRightRadius: "10px",
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
    position: "relative",
  },
  arrow: {
    content: '""',
    color:"#E9E9E9"
  },
}))(Tooltip);

interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string;
}

interface Toggle extends SwitchProps {
  classes: Styles;
}

// Customizable Area End
export const IOSSwitch = withStyles((theme) => ({
 
  root: {
    width: 51,
    height: 31.5,
    padding: 0,
   
    // margin: theme.spacing(1),
  },
  switchBase: {
    padding: 3,
    "&$checked": {
      transform: "translateX(20px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#005E6E",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: "26px",
    height: "26px",
    borderRadious:"20px", 
  },
  track: {
    borderRadius: '20px',
    backgroundColor: "#9e9e9e",
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }: Toggle) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
}) as React.ComponentType<SwitchProps>;

import RecommendationController, {
  Props,
} from "./RecommendationController.web";

import { Divider } from "react-native-elements";

export const Che = ({ ...other }) => {
  return <img src={checked} alt="images" width="24px" height="24px" />;
};
export const Unch = ({ ...other }) => {
  return <img src={unchecked} alt="images" width="24px" height="24px" />;
};


const PrintAdsRedAsterisk = (
  <span
    style={{
      display: "flex",
      alignItems: "center",
      fontFamily: "Panton Narrow Regular",
      fontSize: "16px",
      fontWeight: 400,
      color: "#000000",
    }}
  >
    Print Ads <span style={{ color: "red" }}>*</span>
    <img src={printads} style={{ marginLeft: "20px" }} />
  </span>
);

const BannerAdsRedAsterisk = (
  <span
    style={{
      display: "flex",
      alignItems: "center",
      fontFamily: "Panton Narrow Regular",
      fontSize: "16px",
      fontWeight: 400,
      color: "#000000",
    }}
  >
    Banner Ads <span style={{ color: "red" }}>*</span>
    <img src={bannerad} style={{ marginLeft: "20px" }} />
  </span>
);

const SearchAdsRedAsterisk = (
  <span
    style={{
      display: "flex",
      alignItems: "center",
      fontFamily: "Panton Narrow Regular",
      fontSize: "16px",
      fontWeight: 400,
      color: "#000000",
    }}
  >
    Search Ads <span style={{ color: "red" }}>*</span>
    <img src={google} style={{ marginLeft: "20px" }} />
  </span>
);

const accordionSummaryStyle = {
  borderRadius:"8px",
  border: 'none',
  transition: "border 0.3s ease-in-out, border-radius 0.3s ease-in-out",

};

export  class Recommendation extends RecommendationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  //istanbul ignore next
  accordionRender = (channelMix: any, index: any) => {
    const { classes } = this.props;
    const screenWidth = window.innerWidth;
    const isBelow650 = screenWidth < 650;
    const isBelow390 = screenWidth < 390;
    const isChecked =
        channelMix.mandatory ||
        (channelMix.channel_id &&
            this.state.selectedSubChannelIds.includes(channelMix.channel_id));
    let subChannelsToShowCount;
    if (isBelow390) {
        subChannelsToShowCount = 2;
    } else if (isBelow650) {
        subChannelsToShowCount = 3;
    } else {
        subChannelsToShowCount = channelMix.sub_channels.length;
    }
    const subChannelsToShow = channelMix.sub_channels?.slice(0, subChannelsToShowCount);

    return (
        <CustomAccordion
            key={index}
            elevation={0}
            data-test-id="RecommendationData"
            expanded={this.state.expandedPanel === `panel${index + 1}`}
            onChange={this.handleChangePanel(`panel${index + 1}`)}
        >
            <AccordionSummary
                id={`panel${index + 1}-header`}
                aria-controls={`panel${index + 1}-content`}
                expandIcon={<img src={expandIcon} />}
                style={{
                    ...accordionSummaryStyle,
                    ...(this.state.expandedPanel === `panel${index + 1}` && {
                        border: "1px solid var(--Neutrals-Cool-gray-500, #B4B4B4)",
                        paddingLeft:'16px'
                    }),
                }}
            >
                <FormControlLabel
                    data-test-id="formCheck"
                    control={
                        <Checkbox
                            className={classes.checkBox}
                            style={{
                              pointerEvents: channelMix.mandatory ? 'auto' : 'none',
                              cursor: channelMix.mandatory ? 'default' : 'pointer',
                              paddingLeft:3
                            }}
                            icon={<Unch />}
                            checkedIcon={<Che />}
                            data-test-id="checkBox"
                            defaultChecked={true}
                            checked={isChecked}
                            disabled={this.state.viewData}
                            onChange={(event) => this.handleCheckboxChange(channelMix, event)}
                        />
                    }
                    label={
                        <span className={classes.labelStyle}>
                            <div className={classes.chanelsName}>{(channelMix && channelMix.channel_name) || ""} {channelMix.mandatory && <span style={{ color: "red" }}>*</span>}</div>
                            <div className={classes.subChanels}>
                                {subChannelsToShow?.map((subChannel: any, subIndex: any) =>
                                     this.state.expandedPanel === `panel${index + 1}` ? null : (
                                        <img
                                            key={subChannel.id}
                                            style={{...webStyle.subchannelslogo,marginTop:'2px'}}
                                            src={subChannel.photo}
                                            alt="Channel Image"
                                        />
                                     )
                                )}
                            </div>
                        </span>
                    }
                />
            </AccordionSummary>
            <AccordionDetails style={{ position: "relative", padding: 0 }}>
                <div style={webStyle.serch}>
                    <div style={{ padding: 8 }}>
                        {channelMix &&
                            channelMix.sub_channels &&
                            channelMix.sub_channels.map(
                                (subChannel: any, subIndex: any) => (
                                    <div key={subIndex}>
                                        <MenuItem style={webStyle.Items}>
                                            <img style={webStyle.subchannelslogo} src={subChannel.photo}  />
                                            {subChannel.name}
                                        </MenuItem>
                                        {subIndex < channelMix?.sub_channels.length - 1 && (
                                            <Divider style={{ backgroundColor: "#F8FAFC" }} />
                                        )}
                                    </div>
                                )
                            )}
                    </div>
                </div>
            </AccordionDetails>
        </CustomAccordion>
    );
};




    renderTableHead = () => {
     
    const WhiteOnBlackTooltipView = WhiteOnBlackTooltip as any;
    

    return (
      <Typography style={webStyle.Budget}>
      {this.state.BudgetData && this.state.BudgetData.data && this.state.BudgetData.data.name}
    <span >

      <WhiteOnBlackTooltipView
        TransitionComponent={Zoom}
        title={this.state.BudgetData?.data?.description}
        arrow={true}
        placement="top-start"
      >
        <img
          src={info}
          style={{ marginLeft: "15px" }}
          alt="Info"
        />
      </WhiteOnBlackTooltipView>
</span>
      <Typography style={webStyle.subText}>
        {this.state.RecommendationData?.data?.budget || ''} CHF /month
      </Typography>
    </Typography>
    );
  }

  renderTooltip = () => {
     
    const WhiteOnBlackTooltipView = WhiteOnBlackTooltip as any;
    const DividerView = Divider as unknown as React.FC<DividerProps>

    return (
      <Typography style={{...webStyle.Budget,marginBottom:"20px"}}>
                    {this.state.ChannelsData.data.name}
                   
                    <WhiteOnBlackTooltipView
                      arrow={true}
                      TransitionComponent={Zoom}
                      placement="bottom-start"
                      title={this.state.ChannelsData.data.description}
                    >
                      <img
                        src={info}
                        style={{ marginLeft: "15px" }}
                        alt="Info"
                      />
                    </WhiteOnBlackTooltipView>
                   

                   
                    <DividerView style={webStyle. dividerChanel as any } />
                  </Typography>
    );
  };

  industryData =(index:number,apiData:any)=>{
    return(
        <>
          {index == 0 && <span style={webStyle.time}>{ apiData[0]?.Leads } {this.state.selectlang && this.state.selectlang.time && this.state.selectlang.time[0]}</span>}
          {index == 1 && <span style={webStyle.time}>{ apiData[0]?.Clicks } {this.state.selectlang && this.state.selectlang.time && this.state.selectlang.time[1]}</span>}
          {index == 2 && <span style={webStyle.time}>{ apiData[0]?.Impressions } {this.state.selectlang && this.state.selectlang.time && this.state.selectlang.time[2]}</span>}
          {index == 3 && <span style={webStyle.time}>{ apiData[0]?.TotalSpent } {this.state.selectlang && this.state.selectlang.time && this.state.selectlang.time[3]}</span>}
        </>
    )
  }

  renderButton = () => {
    return (
      <CustomBoxViewButton>                    
                    <ButtonContainer 
                     data-test-id="recommendation_page"
                     disabled={this.state.viewData}
                      onClick={() => {                                             
                        const { RecommendationData } = this.state;
                        const { data } = RecommendationData;
                        const hasValidBudget = data.budget;
                        const hasValidChannelMixes = data.channel_mixes && data.channel_mixes.length > 0;
                  
                        if (!hasValidBudget || !hasValidChannelMixes) {
                          this.setState({ openModel: true });
                        } else {
                          this.handleConfigureClick();
                        }
                      }}        
                    >
                    {this.state.selectlang.buttons[1]}
                    </ButtonContainer>  


                    <ButtonContainer variant="contained" 
                     data-test-id="quote_page"
                     disabled={this.state.viewData}
                      onClick={() => {                       
                        const { RecommendationData } = this.state;
                        const { data } = RecommendationData;
                        const hasValidBudget = data.budget;
                        const hasValidChannelMixes = data.channel_mixes && data.channel_mixes.length > 0;
                  
                        if (!hasValidBudget || !hasValidChannelMixes) {
                          this.setState({ openModel: true });
                        } else {
                          this.handleCheckout();
                        }
                      }}
                    >
                      {this.state.selectlang.buttons[2]}
                    </ButtonContainer>          
                          
                 </CustomBoxViewButton> 
    )
  }

  renderPopup =()=>{
    const BoxView = Box as any;
        return(
          <>
          <Dialog
            open={this.state.openModel}
            data-test-id="openModel"
            style={{ borderRadius: "50px" }}
            PaperProps={{
              style: {
                borderRadius: "12px"
              },
            }}
          >
              <BoxView
                display={"flex"}
                style={{ justifyContent: "flex-end",
                padding:'17px 17px 10px 0px ',
                cursor: "pointer" }}
                onClick={this.closeModal}
              >
                <CloseIcon />
              </BoxView>
              <DialogContent
                style={{color: "#000000",lineHeight: "26px" ,textAlign:"center",padding:"0px 22px 48px 22px", fontFamily: "Panton Narrow Bold",
                fontWeight: 700, fontSize:"24px"}}
              >
                {((!this.state.RecommendationData?.data?.budget && !this.state.RecommendationData?.data?.channel_mixes?.length) || (!this.state.RecommendationData?.data?.budget && this.state.RecommendationData?.data?.channel_mixes?.length)) && (
                  <>{this.state.selectlang.buttons[6]}<br/>{this.state.selectlang.buttons[7]}<br/>{this.state.selectlang.buttons[8]}</>
                )}
                {!this.state.RecommendationData?.data?.channel_mixes?.length && this.state.RecommendationData?.data?.budget && (
                  <>{this.state.selectlang.buttons[3]}<br/>{this.state.selectlang.buttons[4]}<br/>{this.state.selectlang.buttons[5]}</>
                )}
              </DialogContent>
          </Dialog>
        </>
        )
  }
  renderDataSub = () =>{
    const rotationDegree = this.state.isDropdownOpen ? "rotate(180deg)" : "rotate(0deg)";
    const BoxView = Box as any;
    const WhiteOnBlackTooltipView = WhiteOnBlackTooltip as any;

    return(
      <Typography style={{...webStyle.Budget}}>
      {this.state.DurationData.data.name}
      <WhiteOnBlackTooltipView
        arrow={true}
        TransitionComponent={Zoom}
        placement="bottom-start"
        title={this.state.DurationData.data.description}
      >
        <img
          src={info}
          style={{ marginLeft: "15px" }}
          alt="Info"
        />
      </WhiteOnBlackTooltipView>
      <Typography className="subText" style={{ display: "flex",marginTop:"15px" }}>
      {this.state.selectedItem ? this.state.selectedItem : this.state.RecommendationData?.data?.duration} Months
        <img
          src={expandIcon}
          style={{ marginLeft: "15px", cursor: "pointer", transform: rotationDegree,
            transition: "transform 0.3s ease-in-out", }}
          onClick={this.toggleDropdownList}
          alt="Expand"
        />
      </Typography>
      <BoxView
        style={{ position: "relative", display: "inline-block",cursor: "pointer"}}
      >
        {this.state.isDropdownOpen && (
          <BoxView style={webStyle.dropdownList}>
            {this.state.menuItems.map(
              (item, index: number) => (
              <BoxView
                key={index}
                style={{
                  ...webStyle.dropBox,
                  borderBottom:
                    index === this.state.menuItems.length - 1
                      ? "none"
                      : "1px solid #B4B4B4",
                }}
                id='duration'
                onClick={() => this.handleItemClick(item.value)}
              >
                {item.label}
              </BoxView>
            )
            )}
          </BoxView>
        )}
      </BoxView>
    </Typography>
    )
    
  }

  // Customizable Area End
  //istanbul ignore next
  renderToolTipView = () => {
    const BoxView = Box as any;
    const { classes }:any = this.props;
    const WhiteOnBlackTooltipView = WhiteOnBlackTooltip as any;
    const maxCharacters = 202;
    const truncatedText = this.state.recommendationData?.body ? this.state.recommendationData.body.substring(0, maxCharacters) : '';
    const shouldShowReadMore = this.state.recommendationData?.body && this.state.recommendationData.body.length > maxCharacters;

    return (
      < CustomBoxViewSubBox>
                     <Typography style={webStyle.title}>
                        <span >
                          {this.state.recommendationData?.heading}
                        </span>
                        <span>
                        <WhiteOnBlackTooltipView
                          arrow={true}
                          TransitionComponent={Zoom}
                          placement="bottom-start"
                          title={this.state.recommendationData?.tooltip}
                          open={this.state.tooltipOpenRecommendationData}
                          onClose={this.handleTooltipCloseRecommendationData}
                        >
                          <img src={info} 
                          data-test-id='mouserors'
                          onMouseLeave={()=>this.handleRoasMouseOvers()}
                           onMouseEnter={()=>this.handleRoasMouseHovers()}

                        onClick={() => this.handleMouseRoas()}
                          alt="Info" style={{height: "12px", width: "12px"}} />
                        </WhiteOnBlackTooltipView>
                        </span>
                      </Typography>
                  <img src={this.state.recommendationData?.image_url} style={webStyle.img} />
                   <Typography style={{
                        marginTop: "20px",
                        lineHeight: '19.38px',
                        wordBreak: 'break-all',
                        fontSize: '16px',
                        fontWeight: 400,
                        fontFamily: 'panton narrow regular'
                    }} >
                        {truncatedText}{" "}
                        {shouldShowReadMore && (
                            <span style={webStyle.readMore} onClick={this.toggleModal}>
                                {this.state.recommendationData?.read_more}
                            </span>
                        )}
                    </Typography>

                  <Modal
                          open={this.state.isModalOpen}
                          onClose={this.toggleModal}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                          BackdropProps={{
                            style: {
                              backgroundColor: 'transparent',
                            }
                          }}
                        >
                        <BoxView className={classes.recommendationModalBoxStyle} >
                            <div className={classes.recommendationModalBoxStyles}>
                                <Typography className={classes.bodyText}>
                                    {this.state.recommendationData?.body}
                                </Typography>
                                <div  className={classes.recoomendationCancelImageWrapper} >
                                    <img src={cancelImage} alt="Close" className={classes.recomendationCancelImage}  onClick={this.toggleModal} />
                                </div>
                            </div>
                        </BoxView>
                        </Modal>
                </ CustomBoxViewSubBox>
    )
  }
  
  //istanbul ignore next
  render() {
    const BoxView = Box as any;
    const { classes }:any = this.props;
    const WhiteOnBlackTooltipView = WhiteOnBlackTooltip as any;
    const WhiteOnBlackTooltipViewRec = WhiteOnBlackTooltipRec;
    const DividerView = Divider as unknown as React.FC<DividerProps>
    const IOSSwitchView = IOSSwitch as any
    const apiData = this.state.industryBenchmarkData.data || [];
    const hasData = this.state.RecommendationData?.data?.channel_mixes?.length > 0;
    return (
      // Customizable Area Start
      
      <ThemeProvider theme={theme}>
        <Sidebar navigation={this.props.navigation} classes={{}} setItem={this.state.updateObjective} onDashboardSave={this.onDashboardSave}>
        <Custom4BoxView data-test-id='recommendation'>
          <CustomBoxViewleftBox>
             <CustomBoxView >
              <Grid container >
                <Grid item sm={10} xs={12}>
                  <Typography className={classes.lablesHeading} >
                  {this.state.selectlang.buttons[0]}
                  </Typography>
                  
                  <Typography className={classes.lablesBudget} style={{marginTop:'30px'}}>
                  <span style={{display:'flex'}}>
                  {this.state?.recommendationBudgetHeadingTooltip?.data?.name}
                    <WhiteOnBlackTooltipView
                      TransitionComponent={Zoom}
                      title={this.state?.recommendationBudgetHeadingTooltip?.data?.description}
                      arrow={true}
                      placement="top-start"
                      open={this.state.tooltipOpenRecommendationBudget}
                      onClose={this.handleTooltipCloseRecommendationBudget}
                    >
                      <img
                     
                        src={info}
                        style={{ marginLeft: "16px" }}
                        alt="Info"
                        data-test-id='indutry'
                        onMouseLeave={()=>this.handleImageImageMouseOvers()}
                         onMouseEnter={()=>this.handleImageImageMouseHovers()}
                                
                         onClick={() => this.handleMouseIndustyr()}
                      />
                    </WhiteOnBlackTooltipView>
                  </span>
                    <Typography style={webStyle.subText}>
                      {this.state.RecommendationData?.data?.budget} CHF {this.state.selectlang.time[0]}
                    </Typography>
                  </Typography>
                  <Divider style={{...webStyle.divider, marginTop:'20px' as any }} />
                  <Typography className={classes.lablesChanels} style={{marginTop:'24px', marginBottom:'16px' }}>
                   <span style={{ display: 'flex'}}>
                  {this.state?.recommendationChannelHeadingTooltip?.data?.name}

                    <WhiteOnBlackTooltipView
                      arrow={true}
                      TransitionComponent={Zoom}
                      placement="bottom-start"
                      title={this.state?.recommendationChannelHeadingTooltip?.data?.description}
                      open={this.state.tooltipOpenRecommendationChannel}
                      onClose={this.handleTooltipCloseRecommendationChannel}
                    >
                      <img
                        src={info}
                        style={{ marginLeft: "16px" }}
                        alt="Info"
                        data-test-id='location'
                        onMouseLeave={()=>this.handleLocationMouseOvers()}
                        onMouseEnter={()=>this.handleLocationMouseHovers()}
                        
                        onClick={() => this.handleMouseLocation()}
                      />
                    
                    </WhiteOnBlackTooltipView>
                   </span>
                    <DividerView style={{...webStyle.dividerChanel,marginTop:'30px'as any ,backgroundColor:"#b4b4b4"}} />
                  </Typography>
                  
         
                   <ParentAccordian>
                  {
                    this.state.RecommendationData?.data?.channel_mixes && this.state.RecommendationData.data.channel_mixes.map((channelMix:any, index:any) => (
                        this.accordionRender(channelMix, index)
                    ))
                  }
                  </ParentAccordian> 

                  <Typography className={classes.lables} style={{ marginTop: hasData ? '24px' : '48px' }}>
                    <span style={{ display:'flex'}}>
                  {this.state?.recommendationDurationTootlips?.data?.name}
                    <WhiteOnBlackTooltipView
                      arrow={true}
                      TransitionComponent={Zoom}
                      placement="bottom-start"
                      title={this.state?.recommendationDurationTootlips?.data?.description}
                      open={this.state.tooltipOpenRecommendationDuration}
                     onClose={this.handleTooltipCloseRecommendationDuration}
                    >
                      <img
                        src={info}
                        style={{ marginLeft: "16px" }}
                        alt="Info"
                        data-test-id='duration'
                        onMouseLeave={()=>this.handleDurationMouseOvers()}
                        onMouseEnter={()=>this.handleDurationMouseHovers()}
                               
                        onClick={() => this.handleMouseDuration()}
                      />
                    </WhiteOnBlackTooltipView>
                    </span>
                    <Typography className="subText" style={{ display: "flex",marginTop:8 }}>
                    {this.state.selectedItem ? this.state.selectedItem : this.state.RecommendationData?.data?.duration} {this.state.selectlang.time[4]}
                      <img
                        src={expandIcon}
                        style={{ marginLeft: "15px", cursor: "pointer", transform: 'rotationDegree',
                          transition: "transform 0.3s ease-in-out", }}
                        onClick={this.toggleDropdownList}
                        alt="Expand"
                      />
                    </Typography>
                    <BoxView
                      style={{ position: "relative", display: "inline-block" }}
                    >
                      {this.state.isDropdownOpen && (
                        <BoxView style={webStyle.dropdownList}>
                          {this.state.menuItems.map(
                            (item, index: number) => (
                            <BoxView
                              key={index}
                              style={{
                                ...webStyle.dropBox,
                                borderBottom:
                                  index === this.state.menuItems.length - 1
                                    ? "none"
                                    : "1px solid #B4B4B4",
                              }}
                              id='duration'
                              onClick={() => this.handleItemClick(item.value)}
                            >
                              {item.label}{this.state.selectlang?.time[4]}
                            </BoxView>
                          )
                          )}
                        </BoxView>
                      )}
                    </BoxView>
                  </Typography>
                  <Divider style={{...webStyle.divider,marginTop:'-10px' as any}} />
                  {this.state.RecommendationData?.data?.add_ons && this.state.RecommendationData?.data?.add_ons?.map((add_ons:any, index) => (
                      <BoxView
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                         marginTop:'24px',
                        }}
                      >
                        <BoxView key={index} >
                          <Typography className={classes.lables} >
                            <span style={{display:'flex'}}>
                            {add_ons.name}
                            <WhiteOnBlackTooltipView
                              arrow={true}
                              TransitionComponent={Zoom}
                              placement="bottom-start"
                              title={add_ons.tooltip}
                              open={this.state.tooltipAddonsData&&this.state.tooltipAddonsDataId === add_ons.id}
                              onClose={this.handleTooltipCloseAddOns}
                            >
                              <img
                                src={info}
                                style={{ marginLeft: "15px" }}
                                alt="Info"
                                data-test-id='addonsid'
                                onMouseLeave={()=>this.handleAddonsMouseOvers(add_ons.id)}
                                onMouseEnter={()=>this.handleAddonsMouseHovers(add_ons.id)}
                               
                                 onClick={() => this.handleAddonsDuration(add_ons.id)}

                              />
                            </WhiteOnBlackTooltipView>

                            </span>
                            <Typography  className={classes.Text}>
                              {add_ons.discount_description}
                            </Typography>
                          </Typography>
                        </BoxView>
                          <WhiteOnBlackTooltipViewRec
                            title={add_ons.tooltip}
                           open={this.isTooltipOpen(add_ons.id)}
                            arrow={true}
                            TransitionComponent={Zoom}
                            placement="top-end"
                            onClose={this.handleToggleTooltipClose}
                          >
                            <IOSSwitchView
                               disabled={this.state.viewData}
                                  data-test-id="add_ons"
                                  onChange={() => this.handleSwitchChange(add_ons.id.toString())}
                                  checked={this.state.toggle.includes(add_ons.id.toString())}
                                  onMouseLeave={()=>this.handleToggleMouseOvers(add_ons.id)}
                                  onMouseEnter={()=>this. handleToggleMouseHover(add_ons.id)}
                                  onClick={() => this.handleHeading(add_ons.id)}            
                                  className={(this.state.showTooltip && this.state.showTooltip.includes(add_ons.id.toString())) && classes.Cursor}
                            />
                        
                          </WhiteOnBlackTooltipViewRec>
                      </BoxView>
                    ))}
                </Grid>
              </Grid>
            </CustomBoxView> 
          </CustomBoxViewleftBox>
          <CustomBoxViewRight>
          <BoxView style={webStyle.subBox2}>
              <BoxView  sx={webStyle.subcontainer}>
                <BoxView>
                  <BoxView style={webStyle.subBox}>
                    <Typography style={webStyle.titleExplain}>
                    {this.state?.recommendationindustrybBenHeadingTooltip?.data?.name}
                      <WhiteOnBlackTooltipView
                        TransitionComponent={Zoom}
                        title={this.state?.recommendationindustrybBenHeadingTooltip?.data?.description}
                        arrow={true}
                        placement="bottom-start"
                        open={this.state.tooltipOpenRecommendationIndustryBenefit}
                        onClose={this.handleTooltipCloseRecommendationIndustryBenefit}
                      >
                        <img src={info}
                        data-test-id='heading'
                     onMouseLeave={()=>this.handleHeadingMouseOvers()}
                    onMouseEnter={()=>this.handleHeadingMouseHovers()}
       
                   onClick={() => this.handleMouseHeading()}                        style={{ marginLeft: "15px",height:"12px",width:"12px" }} alt="Info" />
                      </WhiteOnBlackTooltipView>
                    </Typography>
                    
                        <div data-test-id="industry_data" style={{padding:'20px 8px 24px 10px'}}>
                 
                    {(this.state.selectlang?.name || []).map((name:any, index:any) => (
                             
                              <BoxView>
                          <BoxView  style={{display:'flex',justifyContent:'space-between',}}>
                          
                       
                        <BoxView style={webStyle.names}>
                          
                        {name}
                        </BoxView>
                         <BoxView  style={webStyle.times}>
                         {apiData && this.industryData(index ,apiData)}
                         </BoxView> 

                         </BoxView>
                          <div style={{ width: '100%', borderTop: '1px solid #B4B4B4' }}></div> 
                         </BoxView>
                            ))}
                   
                  </div>
                    <BoxView style={webStyle.miniBox}>
                      <Typography style={webStyle.rora}>
                      {this.state?.reccRoasHeadingTooltip?.data?.name}
                        <WhiteOnBlackTooltipView
                          TransitionComponent={Zoom}
                          title={this.state?.reccRoasHeadingTooltip?.data?.description}
                          arrow={true}
                          placement="bottom-start"
                          open={this.state.tooltipOpenReccRoas}
                          onClose={this.handleTooltipCloseReccRoas}
                        >
                          <img
                            src={info}
                            style={{ marginLeft: "15px" }}
                            alt="Info"
                            data-test-id='roras'
                            onMouseLeave={()=>this.handleeccRoasMouseOvers()}
                            onMouseEnter={()=>this.handleeccRoasMouseHovers()}
               
                           onClick={() => this.handleMouseeccRoas()}                           />
                        </WhiteOnBlackTooltipView>
                      </Typography>
                      <Typography style={webStyle.rora}>x3.5</Typography>
                    </BoxView>
                  </BoxView>
                </BoxView>
                {this.renderToolTipView()}
              </BoxView>
              <BoxView >
                {this.renderButton()}
                 {/*  */}
              </BoxView>
            </BoxView>
          </CustomBoxViewRight>
        </Custom4BoxView>     
        { ((!this.state.RecommendationData?.data?.budget || !this.state.RecommendationData?.data?.channel_mixes?.length ) && this.state.isLoading) && (
          this.renderPopup()
        
          )}
         
        </Sidebar>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const ButtonContainer :any = styled(Button)({
  width: "176px",
  borderRadius: "43px",
  fontFamily: "Panton Narrow Bold",
  fontWeight: 700,
  height: "52px",
  textTransform: "capitalize" as const,
  color: "#353232",
  backgroundColor: "#e0e0e0",
  boxShadow: "none",
  "&:hover": { 
    backgroundColor: "#005E6E",
    color: "#FFFFFF",
    boxShadow: "none",
  },
});
const webStyle = {
  dropdownList: {
    borderRadius: "12px",
    backgroundColor: "white",
    color: "#666666",
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: "Panton Narrow Regular",
    width: "168px",
    boxShadow: "0px 0px 16px 0px #00000040" as const,
    position: "absolute" as const,
    zIndex: 1,
    marginTop:'-16px'
  },
  dropBox: {
    padding: "14px 24px",
    borderBottom: "1px solid #B4B4B4",
    boxSizing: "border-box" as const,
    width: "100%",
    cursor:'pointer'
  },
  rora: {
    fontFamily: "Panton Narrow Bold",
    fontSize: "24px",
    fontWeight: 700,
  },
  roras: {
    fontFamily: "Panton Narrow Bold",
    fontSize: "24px",
    fontWeight: 700,
    marginRight:"35px"
  },
  readMore: {
    fontFamily: "Panton Narrow Bold",
    fontSize: "14px",
    fontWeight: 700,
    color: "#005E6E",
    cursor:'pointer'
  },
  miniBox: {
    display: "flex",
    justifyContent: "space-between",
    
  },
  names: {
   fontWeight: 400,
   fontFamily: "Panton Narrow Regular",
   fontSize: "16px",
    paddingTop:"10px",
   paddingBottom:"6px",

 },
  times: {
      fontFamily: "Panton Narrow Regular",
      fontSize: "14px",
      fontWeight: 400,
      paddingTop:"10px",
      paddingBottom:"6px",
      
    },
  time: {
    fontFamily: "Panton Narrow Regular",
    fontSize: "14px",
    fontWeight: 400,
    paddingTop:"3.5px",
    paddingBottom:"4px",
    lineHeight:"24px",
    paddingRight:"0px"
  },
  divider: {
    color: "#B4B4B4" as "#B4B4B4",
    backgroundColor:'#b4b4b4'
  },
  dividerChanel: {
    
    marginTop: "15px" as "15px",
    color: "#B4B4B4" as "#B4B4B4",
  },
  Text: {
    fontFamily: "Panton Narrow BOld",
    fontSize: "18px",
    fontWeight: 700,
    marginTop: "8px",
    display: "flex",
    alignItem: "center",
    color: "#005E6E",
  },
  defaultBUtton: {
    fontFamily: "Panton Narrow Bold",
    fontWeight: 700,
    textTransform: "capitalize" as const,
    fontSize: "16px",
    textDecoration: "none",
    cursor:'pointer',
    color: "#353232",
    backgroundColor: "#e0e0e0",
    "&:hover": { 
      backgroundColor: "#005E6E",
      color: "#FFFFFF",
    },
  },
  Items: {
    color: "black",
    fontFamily: "Panton Narrow Regular",
    fontSize: "16px",
    padding: "12px 1px",
    gap:"17px",
    cursor: 'alias',
    "& :hover": {
      backgroundColor: "transparent",
      color: "black",
    },
  },
  subText: {
    fontFamily: "Panton Narrow Regular",
    fontSize: "16px",
    fontWeight: 400,
    marginTop: "10px",
    display: "flex",
    alignItem: "center",
  },
  mainBox: {
    backgroundColor: "#FFFFFF",
    height: "calc(100vh - 100px)",
    // height:"101.5%",
    boxShadow: "0px 4px 16px 0px #00000040",
    padding: "24px 39px",
    borderRadius: "32px",
    
    overflowY: "auto" as const,
    "@media (max-width: 450px)": {
      display: "flex",
      justifyContent: "left" ,
      padding: "20px 15px",
    },
    "&$expanded":{
      margin:0,
      color:"red"
    },
    "& .MuiAccordionSummary":{
      margin: 0,
      minHeight: "0px",
    },
  },
  subcontainer:{
    
    display: "flex",
    flexDirection: "column",
    // height:'calc(100vh - 105px)',
    justifyContent: "space-between",
    "@media (max-width: 600px)": {
      padding:"0px",
      width:"101%"
    },
  },
  
  labelStyle: {
    fontFamily: "Panton Narrow Regular",
    fontSize: "16px",
    fontWeight: 400,
    color: "#000000",
    display:"flex",
    gap:"16px",
    width: "100%",
    minWidth: "80px",
    marginLeft:7
  },
  subBox: {
    backgroundColor: "#FFFFFF",
    height: "auto",
    boxShadow: "0px 4px 16px 0px #00000040",
    
    padding: "32px 20px 32px 20px",
    borderRadius: "24px",
  },
  subBox2:{
    display: "flex",
    flexDirection: "column",
     height: "96%",
    // minHeight:'910px',
    justifyContent: "space-between",
    width:'100%',
    "@ media(max-width:500px)":{
      background:'red',
    },
    [theme.breakpoints.down("xs")]:{
      width:'100% !important',
    },
    [theme.breakpoints.down("sm")]:{
      width:'100%',
      
      marginLeft: '1px',
    },
  },
  subBox1: {
    backgroundColor: "#FFFFFF",
    height: "auto",
    boxShadow: "0px 4px 16px 0px #00000040",
    padding: "20px 20px",
    borderRadius: "24px",
    marginTop: "33%",
    "@ media(max-width:960px)":{
      background:'red',
    },
  },
  button: {
    width: "194px",
    borderRadius: "43px",
    backgroundColor: "#005E6E",
    color: "#FFFFFF",
    fontFamily: "Panton Narrow Bold",
    fontWeight: 700,
    height: "52px",
    fontSize: "16px",
    boxShadow: "none",
    textTransform: "capitalize" as const,
  },
  lable: {
    fontFamily: "Panton Narrow Bold",
    fontSize: "24px",
    fontWeight: 700,
    color: "#000000",
  },
  titleExplain:{
    fontSize: "16px",
    fontWeight: 400,
    color: "#000000",
    fontFamily: "Panton Narrow Regular",
    
    display:"flex",
    alignItems:"center",
  },
  title: {
    fontSize: "16px",
    fontWeight: 400,
    color: "#000000",
    fontFamily: "Panton Narrow Regular",
      marginBottom: "21px",
    display:"flex",
    alignItems:"center",
    
    gap:'12px',
  },
  Budget: {
    fontFamily: "Panton Narrow Bold",
    fontSize: "24px",
    fontWeight: 700,
    color: "#000000",
    marginTop: "20px",
  },
  subchannelslogo: {
    width: "24px",
    height: "24px",
    borderRadius: "50%",
    border: '1px solid lightgray'
  },
  img: {
    width: "100%",
    height:'164px',
  },
  serch: {
    boxShadow: "0px 8px 16px 0px rgba(0, 0, 0, 0.25)",
    borderRadius: "9px",
    zIndex: 999,
    width: "100%",
    background: "#FFFFFF",
  },
  buttonBox: {
    display: "flex",
    justifyContent: "flex-end",
    gap:'38px',
    alignItems: "center",
    "@media (max-width: 1250px)": {
      flexDirection:"row",
      width:"100%",
      gap:"20px",
      marginTop: "15px",
    },
    "@media (max-width: 600px)": {
      flexDirection:"row",
    },
    "@media (max-width: 960px)": {
      gap:"0px",
      marginTop: "40px",
    },
  },
 
  /* YourComponent.css */
};
export const useStyle = (theme: Theme) =>
  createStyles({ 
    lablesHeading: {
      fontFamily: "Panton Narrow Bold !important",
      fontSize: "24px",
      fontWeight: 700,
      color: "#000000",
      display:'flex',
      "@media(max-width:500px)":{
        fontSize: "20px !important",
        lineHeight:'normal',
        left:'-10px !important',
        
      }
    },
    recommendationModalBoxStyles:{
      display: 'flex',
      justifyContent: 'space-between',
      "@media(max-width:500px)":{
       display: 'unset',
       justifyContent:'unset',
      }
    },
     
  recoomendationCancelImageWrapper: {
    alignSelf: 'flex-start',
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'flex-end',
    "@media(max-width:500px)":{
      position:'absolute',
      top:18,
    
      right:'18px',
    }
},
  
  recomendationCancelImage: {
    cursor: 'pointer',
    width: '10.96px',
    height: '11px',
    position:"absolute",
    top:'18px',
    right:'18px',
    "@media(max-width:500px)":{
      position:'absolute',
      top:18,
      marginRight:'0px',
      right:'18px',
    }
  },
    bodyText: {
      flex: 1,
      fontFamily: 'panton narrow regular',
      fontSize: '16px',
      lineHeight: '19.38px',
      fontWeight: 400,
      wordBreak: 'break-all',
       padding: '48px',
     
    },
    recommendationModalBoxStyle: {
      position: 'fixed',
          top: '50%',
          left: '50%',
          right: '50%',
          transform: 'translate(-50%, -50%)',
          width: '573px',
          height: 'auto',
          borderRadius: '12px',
          background: '#FFFFFF',
          boxShadow: '0px 8px 16px 0px rgba(0, 0, 0, 0.25)',
         
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'auto',
          maxHeight: '80vh',
          '@media(max-width:500px)':{
            width: '290px',
            top: '52%',
            overflowY: 'auto',
            
          }
    },
    labelStyle: {
      fontFamily: "Panton Narrow Regular",
      fontSize: "16px",
      fontWeight: 400,
      color: "#000000",
      display:"flex",
      gap:"16px",
      width: "100%",
      minWidth: "80px",
    },
    lables: {
      fontFamily: "Panton Narrow Bold !important",
      fontSize: "24px",
      fontWeight: 700,
      color: "#000000",
     
      "@media(max-width:500px)":{
        fontSize: "16px !important",
        lineHeight:'26px',
        paddingLeft:'22px',
      },
      
    },
    
    lablesBudget: {
      fontFamily: "Panton Narrow Bold !important",
      fontSize: "24px",
      fontWeight: 700,
      color: "#000000",
      
      // display:'flex',
      "@media(max-width:500px)":{
        fontSize: "16px !important",
        lineHeight:'26px',
        paddingLeft:'23px',
      },
      "@media(max-width:380px)":{
        paddingLeft:'4px',
      },
    },
    headingIcon:{
      marginLeft:'16px',
      width:'17px',
      height:'17px',
      marginTop:'10px',
      "@media(max-width:500px)":{
        marginTop:'5px',
      }
    },
    Text: {
      fontFamily: "Panton Narrow BOld",
      fontSize: "18px",
      fontWeight: 700,
      marginTop: "10px",
      display: "flex",
      alignItem: "center",
      color: "#005E6E",
      "@media(max-width:500px)":{
        fontSize: "12px",
      }
    },

    switch:{
      marginTop:'-28px',
      "@media(max-width:500px)":{
        width:'24px',
        height:'24px',
        marginTop:'-41px',
        marginRight:'35px',
        
      }
    },
    lablesChanels:{
      fontFamily: "Panton Narrow Bold !important",
      fontSize: "24px",
      fontWeight: 700,
      color: "#000000",
      "@media(max-width:500px)":{
        fontSize: "16px !important",
        lineHeight:'26px',
        paddingLeft:'20px',
      },
      "@media(max-width:380px)":{
        paddingLeft:'4px',
      },
    },
    dividers: {
      color: "#B4B4B4" as "#B4B4B4",
      marginTop:'-23px',
      "@media(max-width:500px)":{
        marginTop:'-13px',
      }
    },
    checkBox: {
      "@media(max-width:500px)":{
        width:'24px',
        height:'24px',
      }
    },
    chanelsName:{
    display:"flex",
    gap:"5px",
    marginLeft:7,
    marginTop:3,
    cursor: 'alias',
    "@media(max-width:500px)":{
      gap:"2px",
      fontSize:'14px',
    }
    },
    subChanels:{
      display: "flex",
       gap: "16px",
       marginLeft:2,
       cursor: 'alias',
       "@media(max-width:380px)":{
        gap: "10px",
       },
    },
    Cursor:{
      cursor:"default"
    },
    
  })
  export default withRouter(withStyles(useStyle)(Recommendation as any) as any) as any;
// Customizable Area End
